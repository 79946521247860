import axios from 'axios'

export default class StockService {
    static baseUrl = 'https://api.rk.putman.io'

    static fetchAllStocks() {
        return axios(`${this.baseUrl}/stocks`)
        .then(response => response.data.data)
    }

    static fetchStockData(symbol){
        return axios(`${this.baseUrl}/stocks/${symbol}`)
        .then(response => response.data.data)
    }
  }
