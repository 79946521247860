// import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import StockService from './services/StockService';

import { ResponsiveContainer, LineChart, Line, CartesianGrid, YAxis, XAxis } from 'recharts';

function App() {
  const [stocks, setStocks] = useState([]);
  const [currentStock, setCurrentStock] = useState(null);
  const [currentStockData, setCurrentStockData] = useState([]);

  const getDomain = (stockData) => {
    if(stockData.length < 1) return [0, 0]
    stockData.forEach(day => { day.close = parseFloat(day.close)})

    let minMax = [stockData[0].close, stockData[0].close]
    stockData.forEach(day => {
      minMax[0] = minMax[0] > day.close ? day.close : minMax[0];
      minMax[1] = minMax[1] < day.close ? day.close : minMax[1];
    });

    return [Math.round(minMax[0] * .9), Math.round(minMax[1] * 1.1)]
  }

  useEffect(() => {
    StockService.fetchAllStocks()
    .then(data => {
      setStocks(data)
    }) 
  }, []);

  useEffect(() => {
    if(currentStock){
      StockService.fetchStockData(currentStock.symbol)
      .then(data => {
        setCurrentStockData(data.series.reverse())
      })
    }
  }, [currentStock]);

  return (
    <div className="App">
      <h1>Choose a Stonk</h1>
      <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
          Click for Stonks
        </Dropdown.Toggle>

        <Dropdown.Menu>
          { stocks.map(stock => ( <Dropdown.Item as="button" onClick={() => setCurrentStock(stock)} key={stock.symbol}>{stock.symbol} - {stock.full_name}</Dropdown.Item>)) }
        </Dropdown.Menu>
      </Dropdown>

      { currentStock && 
      <div>
        <h3>{currentStock.symbol} - {currentStock.full_name}</h3>
        <ResponsiveContainer width = '95%' height = {500}>
          <LineChart data={currentStockData}>
            <Line type="monotone" dataKey="close" stroke="#8884d8" />
            <CartesianGrid stroke="#ccc" />
            <XAxis dataKey="datetime" />
            <YAxis type="number" domain={getDomain(currentStockData)} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      }
    </div>
  );
}

export default App;
